import { useEffect, useState } from '../../../_snowpack/pkg/react.js';
import { useQuery } from '../../../_snowpack/pkg/@apollo/react-hooks.js';
import { useTenant } from '../../hooks/index.js';
import { useToastNotification } from '../../components/toast/index.js';
import { GET_TENANT_METRICS } from './graph.js';
const TENANT_PLAN_URL = 'https://shop.crystallize.com/api/tenant/';
const tenantsWithPaperPlan = ['bos-ecom-prod', 'bos-ecom-preprod', 'bos-ecom-test', 'bos-ecom-devtest', 'bos-ecom-qa'];
export default function UsageAlert() {
  const {
    tenant: {
      id: tenantId,
      identifier: tenantIdentifier
    }
  } = useTenant();
  const toast = useToastNotification();
  const initialMetrics = {
    items: {
      count: 0
    },
    apiCalls: {
      count: 0
    },
    order: {
      count: 0
    },
    webhooks: {
      count: 0
    },
    bandwidth: {
      count: 0
    }
  };
  const [planName, setPlanName] = useState('');
  const [metrics, setMetrics] = useState(initialMetrics);
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const startDate = getIsoDate(year, month, true);
  const endDate = getIsoDate(year, month, false);
  const {
    data
  } = useQuery(GET_TENANT_METRICS, {
    variables: {
      startDate,
      endDate,
      tenantId
    }
  });
  useEffect(() => {
    var _data$tenant, _data$tenant$get, _data$tenant2, _data$tenant2$get;

    if (data !== null && data !== void 0 && (_data$tenant = data.tenant) !== null && _data$tenant !== void 0 && (_data$tenant$get = _data$tenant.get) !== null && _data$tenant$get !== void 0 && _data$tenant$get.metrics) setMetrics(data === null || data === void 0 ? void 0 : (_data$tenant2 = data.tenant) === null || _data$tenant2 === void 0 ? void 0 : (_data$tenant2$get = _data$tenant2.get) === null || _data$tenant2$get === void 0 ? void 0 : _data$tenant2$get.metrics);
  }, [data]);
  useEffect(() => {
    const getPlan = async () => {
      const response = await fetch(`${TENANT_PLAN_URL}${tenantId}`);

      if (response.type === 'error') {
        console.error('Could not fetch tenant plan');
      } else {
        var _await$response$json;

        const {
          success,
          tenant
        } = (_await$response$json = await (response === null || response === void 0 ? void 0 : response.json())) !== null && _await$response$json !== void 0 ? _await$response$json : {};

        if (success && tenant !== null && tenant !== void 0 && tenant.plan) {
          setPlanName(tenant.plan.name);
        }
      }
    };

    getPlan();
  }, [tenantId]); // TODO: Confirm the algorithm to show user when FREE tier limit has exceeded

  useEffect(() => {
    var _metrics$items;

    if (metrics && planName && (metrics === null || metrics === void 0 ? void 0 : (_metrics$items = metrics.items) === null || _metrics$items === void 0 ? void 0 : _metrics$items.count) >= 1000) {
      toast.open({
        title: 'Reaching the limits',
        description: `Looks like you will soon hit the FREE tier limits!`,
        onCloseLabel: 'Close',
        image: 'limits',
        withClose: true,
        withClick: false
      });
    }
  }, [metrics, planName, toast]);

  if (tenantIdentifier && tenantsWithPaperPlan.includes(tenantIdentifier)) {
    return null;
  }

  return null;
}

const getIsoDate = (year, month, start) => {
  const firstDay = 1;
  const lastDay = new Date(year, month + 1, 0).getDate();
  return new Date(Date.UTC(year, month, start ? firstDay : lastDay)).toISOString();
};