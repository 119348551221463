import { C as Ce } from './common/styled-components.browser.esm-283cd940.js';
import './common/process-2545f00a.js';
import './common/index-19deb36c.js';
import './common/_commonjsHelpers-37fa8da4.js';
import './common/index-ec48241a.js';
import './common/hoist-non-react-statics.cjs-ed5b3fa8.js';

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

var styledIf = function styledIf(method, condition) {
  return function () {
    for (var _len = arguments.length, names = new Array(_len), _key = 0; _key < _len; _key++) {
      names[_key] = arguments[_key];
    }

    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return function (props) {
        return (method === 'match' ? props[names[0]] === names[1] : names[method](function (name) {
          return Boolean(props[name]) === condition;
        })) && Ce.apply(void 0, _toConsumableArray(handleFunctions(args, props)));
      };
    };
  };
};

var handleFunctions = function handleFunctions(args, props) {
  var css = '';

  for (var i = 1; i < args.length; i++) {
    if (typeof args[i] === 'function') {
      var output = args[i](props);

      if (typeof output === "string" && output.includes(':')) {
        css += output;
      }
    }
  }

  if (css) {
    var newArgs = args.slice(0);
    var argCss = args[0].slice(1);
    argCss.unshift(css + newArgs[0][0]);
    newArgs[0] = argCss;
    return newArgs;
  }

  return args;
};

var is = styledIf('every', true);
var isNot = styledIf('every', false);
var match = styledIf('match');

export default is;
export { isNot, match };
