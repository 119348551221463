import styled from '../../../_snowpack/pkg/styled-components.js';
export const Toast = styled.div.withConfig({
  displayName: "styles__Toast",
  componentId: "m4qczp-0"
})(["background-color:#fff;bottom:0;box-shadow:0px -1px 10px rgba(0,0,0,0.1);isolation:isolate;left:0;position:fixed;right:0;z-index:1;"]);
export const Inner = styled.div.withConfig({
  displayName: "styles__Inner",
  componentId: "m4qczp-1"
})(["align-items:center;border:1px solid transparent;display:flex;gap:24px;margin:0 auto;max-width:1024px;position:relative;p{color:#4a4a4a;}&.new-feature{margin-left:250px;}"]);
export const Illustration = styled.div.withConfig({
  displayName: "styles__Illustration",
  componentId: "m4qczp-2"
})(["bottom:10px;overflow:hidden;position:absolute;&.new-version{width:70px;left:130px;bottom:-30px;}&.new-feature{left:65px;width:236px;}&.limits{bottom:-4px;width:120px;}img{width:100%;}"]);
export const ToastInner = styled.div.withConfig({
  displayName: "styles__ToastInner",
  componentId: "m4qczp-3"
})(["padding:24px 100px;position:relative;"]);
export const Blob = styled.div.withConfig({
  displayName: "styles__Blob",
  componentId: "m4qczp-4"
})(["position:absolute;overflow:hidden;width:50%;left:0;top:0;bottom:0;&:before{content:'';height:320px;background:#e2feff;width:320px;position:absolute;border-radius:50%;left:0;top:-90px;}"]);
export const Communication = styled.div.withConfig({
  displayName: "styles__Communication",
  componentId: "m4qczp-5"
})(["display:flex;flex-direction:column;gap:16px;color:#4a4a4a;h3,p{font-family:Roboto;margin:0;}p{line-height:1.25;}"]);
export const Actions = styled.div.withConfig({
  displayName: "styles__Actions",
  componentId: "m4qczp-6"
})(["flex-shrink:0;display:flex;align-items:center;gap:8px;"]);
export const ButtonBase = styled.button.withConfig({
  displayName: "styles__ButtonBase",
  componentId: "m4qczp-7"
})(["cursor:pointer;padding:8px 16px;border:none;border-radius:2px;color:#528693;background-color:#bff6f8;font-weight:700;&.text{background-color:#fff;}&:hover{filter:brightness(96%);}"]);